var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"RutaX","title":"RutaX"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'ni ni-tv-2 text-primary',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Agentes',
          icon: 'ni ni-badge text-red',
          path: '/agentes',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Nuevos clientes',
          icon: 'fas fa-users text-purple',
          path: '/clientes',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Transacciones',
          icon: 'ni ni-basket text-green',
          path: '/transacciones',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Rutas',
          icon: 'ni ni-delivery-fast text-blue',
          path: '/rutas',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Visitas',
          icon: 'ni ni-chart-bar-32 text-green',
          path: '/visitas',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Configuración',
          icon: 'ni ni-settings text-gray',
          path: '/configuracion',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Mi cuenta',
          icon: 'ni ni-single-02 text-orange',
          path: '/profile',
        }}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar',{attrs:{"user":_vm.user}}),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }