import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'

const cookieDuration = '1d' // 1 day

Vue.use(Vuex)
Vue.use(VueCookies)
export default new Vuex.Store({
  state: {
    accessToken: null,
    roles: ['guest'],
    user: null,
    account: null,
    pusher: null,
  },
  mutations: {
    setToken(state, token) {
      state.accessToken = token
      Vue.$cookies.set('accessToken', token, cookieDuration)
    },
    clearToken(state) {
      state.accessToken = null
      Vue.$cookies.remove('accessToken')
    },
    clearAll(state) {
      state.accessToken = null
      state.roles = ['guest']
      state.user = null
      state.account = null
      Vue.$cookies.remove('accessToken')
      Vue.$cookies.set('roles', ['guest'], cookieDuration)
      Vue.$cookies.remove('user')
      Vue.$cookies.remove('account')
      Vue.$cookies.remove('pusher')
    },
    setRoles(state, roles) {
      state.roles = roles
      Vue.$cookies.set('roles', roles, cookieDuration)
    },
    setUserInfo(state, user) {
      state.user = user
      Vue.$cookies.set('user', user, cookieDuration)
    },
    setAccountInfo(state, account) {
      state.account = account
      Vue.$cookies.set('account', account, cookieDuration)
    },
    setPusherInfo(state, pusher) {
      state.pusher = pusher
      Vue.$cookies.set('pusher', pusher, cookieDuration)
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {

  }
})
