import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import store from './store/index'
Vue.use(Router)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    component: DashboardLayout,
    meta: {
      requieresAuth: true,
    },
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          requieresAuth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ './views/Dashboard.vue')
      },
      {
        path: '/visitas',
        name: 'visitas',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "visits" */ './views/Visits.vue')
      },
      {
        path: '/visita/:id',
        name: 'visita',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "visit" */ './views/Visit.vue')
      },
      {
        path: '/profile',
        name: 'mi cuenta',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "userprofile" */ './views/UserProfile.vue')
      },
      {
        path: '/maps',
        name: 'maps',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "maps" */ './views/Maps.vue')
      },
      {
        path: '/configuracion',
        name: 'configuracion',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "settings" */ './views/Settings/Settings.vue')
      },
      {
        path: '/agentes',
        name: 'agentes',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "agents" */ './views/Agents.vue')
      },
      {
        path: '/agente/:id',
        name: 'agente',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "agent" */ './views/Agent.vue')
      },
      {
        path: '/rutas',
        name: 'rutas',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "routes" */ './views/Routes.vue')
      },
      {
        path: '/ruta/:id',
        name: 'ruta',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "route" */ './views/Route.vue')
      },
      {
        path: '/clientes',
        name: 'clientes',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "customers" */ './views/NewClients.vue')
      },
      {
        path: '/transacciones',
        name: 'transacciones',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "transactions" */ './views/Transactions.vue')
      },
      {
        path: '/transaccion/:id',
        name: 'transaccion',
        meta: {
          requieresAuth: true,
        },
        component: () => import(/* webpackChunkName: "agent" */ './views/Transaction.vue')
      },
      // {
      //   path: '/licencias',
      //   name: 'licencias',
      //   meta: {
      //     requieresAuth: true,
      //   },
      //   component: () => import(/* webpackChunkName: "licenses" */'./views/Licenses.vue')
      // },
    ]
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
      },
      /*{
        path: '/register',
        name: 'register',
        component: () => import('./views/Register.vue')
      }*/
    ]
  },
  {
    path: '/v/:secret',
    name: 'visita-cliente',
    meta: {
      requieresAuth: false,
    },
    component: () => import(/* webpackChunkName: "clientvisit" */ './views/ClientVisit.vue')
  },
]

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requieresAuth = to.matched.some(record => record.meta.requieresAuth)
  if (requieresAuth) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.state.accessToken === null &&
      Vue.$cookies.get("accessToken") === null) {
      next({ path: '/login' })
    } else {
      store.commit("setToken", Vue.$cookies.get("accessToken"));
      store.commit("setUserInfo", Vue.$cookies.get("user"));
      store.commit("setAccountInfo", Vue.$cookies.get("account"));
      store.commit("setPusherInfo", Vue.$cookies.get("pusher"));
      next() // does not require auth, make sure to always call next()!
    }
  } else {
    next()
  }
})

export default router
