<template>
  <card class="card-stats" :show-footer-line="true">
    <div class="text-center">
      <h3 class="card-title text-uppercase text-muted mb-0" v-if="title">
        {{ title }}
      </h3>
    </div>
    <div class="text-center" v-if="$slots.icon || icon">
      <slot name="icon">
        <div class="h1">
          <i :class="icon"></i>
        </div>
      </slot>
    </div>
    <div class="row">
      <div class="col text-center">
        <slot name="value"> </slot>
      </div>
    </div>
  </card>
</template>
<script>
import Card from "./Card.vue";

export default {
  name: "license-card",
  components: {
    Card,
  },
  props: {
    icon: String,
    title: String,
  },
};
</script>
<style></style>
