<template>
  <table class="table tablesorter" :class="tableClass">
    <thead :class="theadClasses">
      <tr>
        <slot name="columns" :columns="columns">
          <th v-for="column in columns" :key="column">{{ column }}</th>
        </slot>
      </tr>
    </thead>
    <tbody :class="tbodyClasses">
      <draggable style="display: contents" v-if="drag" v-model="rows" @end="dragged">
        <tr v-for="(item, index) in rows" :key="index">
          <slot :row="item" :index="index">
            <td
              v-for="(column, index) in colsWithValue(item)"
              :key="index">
              {{ itemValue(item, column) }}
            </td>
          </slot>
        </tr>
      </draggable>
      <tr v-else v-for="(item, index) in rows" :key="index">
        <slot :row="item" :index="index">
          <td
            v-for="(column, index) in colsWithValue(item)"
            :key="index">
            {{ itemValue(item, column) }}
          </td>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  name: 'base-table',
  data() {
    return {
      rows: []
    }
  },
  watch: {
    data(newVal, oldVal) {
      this.rows = newVal
    }
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
      description: 'Table columns'
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Table data'
    },
    drag: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String, // striped | hover
      default: '',
      description: 'Whether table is striped or hover type'
    },
    theadClasses: {
      type: String,
      default: '',
      description: '<thead> css classes'
    },
    tbodyClasses: {
      type: String,
      default: '',
      description: '<tbody> css classes'
    }
  },
  computed: {
    tableClass() {
      return this.type && `table-${this.type}`;
    },
    colsWithValue() {
      return (row) => {
        return this.columns.filter(column => this.hasValue(row, column))
      }
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    dragged() {
      this.$emit('dragged', this.rows)
    },
  },
  components: {
    draggable
  }
};
</script>
<style></style>
