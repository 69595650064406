<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="RutaX"
      title="RutaX"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard',
          }"
        />
        <!-- <sidebar-item
          :link="{
            name: 'Licencias',
            icon: 'fas fa-key text-yellow',
            path: '/licencias',
          }"
        /> -->
        <sidebar-item
          :link="{
            name: 'Agentes',
            icon: 'ni ni-badge text-red',
            path: '/agentes',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Nuevos clientes',
            icon: 'fas fa-users text-purple',
            path: '/clientes',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Transacciones',
            icon: 'ni ni-basket text-green',
            path: '/transacciones',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Rutas',
            icon: 'ni ni-delivery-fast text-blue',
            path: '/rutas',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Visitas',
            icon: 'ni ni-chart-bar-32 text-green',
            path: '/visitas',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Configuración',
            icon: 'ni ni-settings text-gray',
            path: '/configuracion',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Mi cuenta',
            icon: 'ni ni-single-02 text-orange',
            path: '/profile',
          }"
        />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar :user="user"></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";
export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      sidebarBackground: "vue",
      user: this.$store.state.user,
    };
  },
  mounted() {
    Vue.use(require("vue-pusher"), {
    api_key: "1764ee0d89ee400a43ed",
    options: {
      cluster: "us2",
      encrypted: true,
      authEndpoint: `${process.env.VUE_APP_API_URL}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${this.$store.state.accessToken}`,
        },
      },
    },
  });
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss">
</style>
