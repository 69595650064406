import axios from 'axios'
export default {
    data() {
        return {
            url: `${process.env.VUE_APP_API_URL}/api`
        }
    },
    methods: {
        async httpGet(endpoint) {
            let response
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (this.$store.state.accessToken ?? '')
                }
            }
            await axios.get(this.url + endpoint, config)
                .then(res => {
                    if (res.data.status == 'success') {
                        response = {
                            status: 'success',
                            data: res.data.data
                        }
                    } else {
                        response = {
                            status: 'error',
                            message: res.data.message
                        }
                    }
                })
                .catch(err => {
                    response = {
                        status: 'error',
                        message: err.response.data.message
                    }
                })
            return response
        },
        async httpPost(endpoint, data) {
            let response
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (this.$store.state.accessToken ?? '')
                }
            }
            await axios.post(this.url + endpoint, data, config)
                .then(res => {
                    if (res.data.status == 'success') {
                        response = {
                            status: 'success',
                            data: res.data.data
                        }
                    } else {
                        response = {
                            status: 'error',
                            message: res.data.message
                        }
                    }
                })
                .catch(err => {
                    response = {
                        status: 'error',
                        message: err.response.data.message
                    }
                })
            return response
        },
        async httpPut(endpoint, data) {
            let response
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (this.$store.state.accessToken ?? '')
                }
            }
            await axios.put(this.url + endpoint, data, config)
                .then(res => {
                    if (res.data.status == 'success') {
                        response = {
                            status: 'success',
                            data: res.data.data
                        }
                    } else {
                        response = {
                            status: 'error',
                            message: res.data.message
                        }
                    }
                })
                .catch(err => {
                    response = {
                        status: 'error',
                        message: err.response.data.message
                    }
                })
            return response
        },
        async httpDelete(endpoint) {
            let response
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + (this.$store.state.accessToken ?? '')
                }
            }
            await axios.delete(this.url + endpoint, config)
                .then(res => {
                    if (res.data.status == 'success') {
                        response = {
                            status: 'success',
                            data: res.data.data
                        }
                    } else {
                        response = {
                            status: 'error',
                            message: res.data.message
                        }
                    }
                })
                .catch(err => {
                    response = {
                        status: 'error',
                        message: err.response.data.message
                    }
                })
            return response
        }
    }
}