import httpMixin from "./httpMixin";
export default {
    methods: {
        logout() {
            this.httpPost("/logout", [])
                .then(async (res) => {
                    await this.$store.commit("clearToken");
                    location.reload()
                })
                .catch((err) => {
                    return false;
                });
        }
    },
    mixins: [httpMixin]
}